
import { defineComponent } from "vue";
import Button from "primevue/button";

export default defineComponent({
  name: "Loan",
  components: {
    Button,
  },
  data() {
    return {
      name: process.env.VUE_APP_TITLE,
    };
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE + " | Prestamos personales";
    window.scrollTo(0, 0);
  },
});
